import { Typography, Box, TextField, Button }  from '@teampassword/teampassword-design-system';
import parse from 'html-react-parser';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

const AccountStep = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="bold"
      >
        {t('signup.accountStep.title')}
      </Typography>
      <Typography
        variant="subtitle2"
        color="grey.500"
      >
        {t('signup.accountStep.subtitle')}
      </Typography>
      {props.couponCode &&
        <Alert severity="success" sx={{marginTop: "10px"}}>{t('signup.accountStep.couponAlert')} "{props.couponCode}".</Alert>
      }
      <Box
        display="inline-block"
        paddingTop="10px"
        paddingBottom="10px"
      >
        {props.showErrorMessage.status && (
          <div>
            <Alert severity="error">{props.showErrorMessage.message}</Alert>
          </div>
        )}
        <TextField
          variant="outlined"
          fullWidth={true}
          label={t('signup.accountStep.yourName')}
          multiline={false}
          margin="normal"
          size="small"
          value={props.adminName}
          required={true}
          onChange={props.adminNameInputHandler}
          onBlur={props.isAdminNameValid}
          error={props.adminNameError.status}
          helperText={props.adminNameError.message}
        />
        <TextField
          variant="outlined"
          fullWidth={true}
          label={t('signup.accountStep.teamName')}
          margin="normal"
          size="small"
          value={props.teamName}
          required={true}
          onChange={props.teamNameInputHandler}
          onBlur={props.isTeamNameValid}
          error={props.teamNameError.status}
        />
        {props.teamNameError.status &&
          <Typography
            fontSize={12}
            lineHeight={1.5}
            margin="4px 14px 0 14px"
            color="error.main"
          >
            {parse(props.teamNameError.message)}
          </Typography>
        }
        <TextField
          variant="outlined"
          fullWidth={true}
          label="email@company.com"
          margin="normal"
          size="small"
          value={props.email}
          required={true}
          onChange={props.emailInputHandler}
          onBlur={props.isEmailUnique}
          error={props.emailError.status}
        />
        {props.emailError.status &&
          <Typography
            fontSize={12}
            lineHeight={1.5}
            margin="4px 14px 0 14px"
            color="error.main"
          >
            {parse(props.emailError.message)}
          </Typography>
        }
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        disabled={!props.adminName || !props.teamName || !props.email ||
          props.adminNameError.status || props.teamNameError.status || props.emailError.status}
        onClick={props.verifyAccountInputs}
      >
        {t('signup.accountStep.btn')}
      </Button>
    </>
  )
}

export default AccountStep;
