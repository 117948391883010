import { Typography, Box, Image, Button, Stack }  from '@teampassword/teampassword-design-system';
import logo from "../../images/teampassword-logo-bg-white.png";
import { useTranslation } from 'react-i18next';

const Header = (props) => {
  const { t } = useTranslation();
  return (
    <Box
      display="inline-block"
      bgcolor="white"
      width="100%"
      border="solid"
      borderColor="grey.100"
      borderTop="0"
      borderRight="0"
      borderLeft="0"
    >
      <Box
        display="block"
        bgcolor="white"
        sx={{
          padding: {xs: "24px 40px", sm: "24px 60px", md: "24px 80px", lg: "24px 80px"}
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          alignContent="flex-start"
          justifyContent="space-between"
          alignItems="center"
        >
          <Image
            alt="Logo"
            objectFit="scale-down"
            src={logo}
            style={{ maxWidth: '162px' }}
          />
          { props.showSignIn &&
            <Stack
              direction="row"
              spacing={0}
              alignContent="flex-end"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                display: {md: "none"}
              }}
            >
              <Typography
                variant="inherit"
                fontWeight="normal"
                color="primary.main"
                paddingRight="12px"
                fontSize={14}
                sx={{
                  display: {xs: "none", sm: "block", md: "none"}
                }}
              >
                {t('signup.header.signinCta')}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                href="/dashboard#login"
              >
                  {t('signup.header.signinBtn')}
              </Button>
            </Stack>
          }
        </Stack>
      </Box>
    </Box>
  )
}

export default Header;
