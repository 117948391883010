import React, { useEffect } from "react";
import { Typography, Grid, Box, Image, Button, Stack, Divider, Link }  from '@teampassword/teampassword-design-system';
import Header from '../components/signup/Header';
import checkIcon from "../images/check_icon.svg";
import lockOpen from "../images/lock_open.svg";
import chromeImg from "../images/chrome_extension.png";
import firefoxImg from "../images/firefox_extension.png";
import safariImg from "../images/safari_extension.png";
import edgeImg from "../images/edge_extension.png";
import appQRCode from "../images/app_qr_code.png";
import { useTranslation } from 'react-i18next';

function OnboardingSuccess() {
  const { t } = useTranslation();

  const redirectToDashboard = () => {
    localStorage.removeItem("session_exp");
    window.location.replace('/dashboard');
  }

  useEffect(() => {
    const rightGridBox = document.getElementById("blue-box");
    rightGridBox.parentElement.style.height = "100%";
  }, [])


  return(
    <Grid
      container={true}
      item={true}
      wrap="wrap"
      direction="row"
      height="100%"
      lg={12}
      md={12}
      sm={12}
    >
      <Grid
        item={true}
        xs={12}
        container={false}
        md={5}
      >
        <Header/>
        <Box
          display="inline-block"
          bgcolor="white"
          sx={{ padding: {xs: "40px", sm: "60px", md: "110px 80px", lg: "110px 100px"} }}
        >
          <Box>
            <Typography
              variant="h6"
              color="text.primary"
              fontWeight="bold"
            >
              {t("success.title")}
            </Typography>
            <Typography
              variant="subtitle2"
              color="grey.500"
              display="inline"
            >
              {t("success.subtitle")}
            </Typography>
            <Link
              rel="noopener noreferrer"
              href={'mailto:support@teampassword.com'}
              color="secondary"
              paddingLeft="8px"
            >
              support@teampassword.com.
            </Link>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disabled={false}
            sx={{ margin: '20px 0 30px 0', lineHeight: "24px" }}
            onClick={redirectToDashboard}
          >
            {t("success.goToDashboard")}
          </Button>
          <Typography
            variant="h6"
            color="text.primary"
            fontWeight="bold"
          >
            {t("success.extensionTitle")}
          </Typography>
          <Typography
            variant="subtitle2"
            color="grey.500"
          >
            {t("success.extensionSubtitle")}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            paddingTop="16px"
          >
            <Link
              rel="noopener"
              target="_blank"
              href={'https://chrome.google.com/webstore/detail/teampassword/cikdbigchmgednjbjppflefljobhnhca'}
            >
              <Image
                src={chromeImg}
                alt="Chrome Extension"
                width="42px"
                height="42px"
              />
            </Link>
            <Link
              rel="noopener"
              target="_blank"
              href={'/firefox'}
            >
              <Image
                src={firefoxImg}
                alt="Firefox Extension"
                width="42px"
                height="42px"
              />
            </Link>
            <Link
              rel="noopener"
              target="_blank"
              href={'https://apps.apple.com/us/app/teampassword-extension/id6447163485'}
            >
              <Image
                src={safariImg}
                alt="Safari Extension"
                width="42px"
                height="42px"
              />
            </Link>
            <Link
               rel="noopener"
               target="_blank"
               href={'https://chrome.google.com/webstore/detail/teampassword/cikdbigchmgednjbjppflefljobhnhca'}
            >
              <Image
                src={edgeImg}
                alt="Edge Extension"
                width="42px"
                height="42px"
              />
            </Link>
          </Stack>
          <Divider
            sx={{ padding: '20px 0 20px 0' }}
          >
          </Divider>
          <Typography
            variant="h6"
            color="text.primary"
            fontWeight="bold"
            paddingTop="40px"
            paddingBottom="10px"
          >
            {t("success.appTitle")}
          </Typography>
          <Typography
            variant="subtitle2"
            color="grey.500"
          >
            {t("success.appSubtitle")}
          </Typography>
          <Stack
            spacing={2}
            paddingTop="30px"
            paddingBottom="30px"
            sx={{ flexDirection: {sx: "column", sm: "row", md: "column", lg: "row" } }}
          >
            <Box
              display="flex"
              border="dashed"
              borderColor="grey.300"
              borderRadius="12px"
              paddingTop="8px"
              paddingRight="8px"
              paddingBottom="8px"
              paddingLeft="8px"
              alignItems="center"
              width="130px"
              marginRight="24px"
            >
              <Image
                src={appQRCode}
                alt="App Download QR code"
                width="130px"
                objectFit="scale-down"
              />
            </Box>
            <Typography
              variant="caption"
              color="grey.500"
              minWidth="150px"
            >
              {t("success.qrCode")}
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item={true}
        xs={12}
        container={false}
        zeroMinWidth={false}
        md={7}
        sx={{ display: {xs: "none", md: "inline-block"} }}
      >
        <Box
          id="blue-box"
          height="100%"
          display="block"
          bgcolor="primary.main"
          sx={{ position: 'relative'}}
        >
          <Box
            display="inline-block"
            bgcolor="secondary.main"
            width="100%"
            height="10px"
            sx={{ position: 'absolute', top: '0' }}
          >
          </Box>
          <Box
            display="block"
            bgcolor="primary.main"
            sx={{ padding: {xs: "40px", sm: "60px", md: "190px 80px", lg: "190px 100px"} }}
          >
            <Box
              display="inline-block"
            >
              <Typography
                variant="h4"
                color="white"
                fontWeight="bold"
              >
                {t("onboardingSteps.title")}
              </Typography>
              <Typography
                variant="subtitle1"
                color="grey.300"
                paddingTop="8px"
                sx={{ maxWidth: '500px' }}
              >
                {t("onboardingSteps.subtitle")}
              </Typography>
            </Box>
            <Stack
              direction="column"
              spacing={0}
              paddingTop="12px"
              paddingBottom="28px"
              sx={{ borderBottom: '1px solid rgba(99, 217, 229, 0.25)' }}
            >
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="grey.500"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                >
                  <Image src={checkIcon} alt="Check Icon" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="grey.500"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.createGroups")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="grey.500"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="grey.500"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                >
                  <Image src={checkIcon} alt="Check Icon" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="grey.500"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.inviteMembers")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="grey.500"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                  bgcolor="grey.500"
                  borderRadius="20px"
                >
                  <Image src={checkIcon} alt="Check Icon" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="grey.500"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.importRecords")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="secondary.main"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                  direction="row"
                  spacing={2}
                  hasDivider={false}
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="24px"
                    height="24px"
                    borderRadius="20px"
                    bgcolor="text.secondary"
                  >
                    <Image src={lockOpen} alt="lock-open" width="14" height="14">
                    </Image>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    fontWeight="bold"
                  >
                    {t("onboardingSteps.success")}
                  </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default OnboardingSuccess;
