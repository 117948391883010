export function getCookie(name) {
  const cookieValue = document.cookie
  .split('; ')
  .find((row) => row.startsWith(name))
  ?.split('=')[1];

  return cookieValue;
}

export function setCookie(key, value){
  const encodedValue = encodeURIComponent(value);
  document.cookie = `${key}=${encodedValue}`;
}

export function validEmailAddress(emailAddress){
  // eslint-disable-next-line
  const emailRegex = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
  return emailRegex.test(emailAddress);
}
export function isDev(){
  return process.env.NODE_ENV === "development";
}

export function passwordGenerator(length, includeSymbols, includeDigits) {
  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const symbols = '!@#$%^&*()+';
  const digits = '1234567890';
  const minChars = 2;

  let all = lowerCase + upperCase;
  let holder = [];

  const randomChar = (chars) => {
    // generate random characters
    return chars.charAt(Math.floor(Math.random() * chars.length))
  }

  // add 2 lower case characters to holder array
  for (let i = 0; i < minChars; i++) { holder += randomChar(lowerCase) }
  // add 2 upper case characters to holder array
  for (let i = 0; i < minChars; i++) { holder += randomChar(upperCase) }

  if (includeSymbols) {
    // if include symbols, add 2 symbols characters to holder array
    for (let i = 0; i < minChars; i++) { holder += randomChar(symbols) }
    all += symbols;
  }

  if (includeDigits) {
    // if include digits, add 2 digits characters to holder array
    for (let i = 0; i < minChars; i++) { holder += randomChar(digits) }
    all += digits;
  }

  // add more characters if the length params is less then holder.length
  while (holder.length < length) {
    holder += randomChar(all);
  }

  return holder.split("").sort(() => 0.5 - Math.random()).join("");
}
