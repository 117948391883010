import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Image, TextField, Button, Stack, Link, CircularProgress }  from '@teampassword/teampassword-design-system';
import lockOpen from "../images/lock_open.svg";
import lockClosed from "../images/lock_closed.svg";
import Header from '../components/signup/Header';
import axios from "axios";
import { Honeybadger } from '@honeybadger-io/react';
import { useParams } from "react-router-dom";
import useCsrfToken from "../hooks/useCsrfToken";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function OnboardingGroups() {
  let { org_id } = useParams();
  const csrfToken = useCsrfToken();
  let navigate = useNavigate();

  const { t } = useTranslation();

  const errorInitialState = {status: false, message: ""};
  const [groups, setGroups] = useState([{name: "", order: 1, error: errorInitialState}]);
  const [isGroupsEmpty, setIsGroupsEmpty] = useState(true);
  const [isAddGroupDisabled, setIsAddGroupDisabled ] = useState(true);

  const [loading, setLoading] = useState(false);
  const disallowedChars = /[<>]/;

  const notEmpty = (value) => {
    return value.length > 0;
  }

  useEffect(() => {
    const rightGridBox = document.getElementById("blue-box");
    rightGridBox.parentElement.style.height = "100%";
  }, [])

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const response = await axios.get(`/api/organizations/${org_id}`);
        setIsGroupsEmpty(!notEmpty(response.data.groups))
      } catch (error) {
        Honeybadger.notify(error);
      }
    }
    getOrganization();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    //Check if there's no empty Group Name before allowing to add more names input.
    const emptyGroupName = [];

    groups.forEach(attr => {
      if(attr.name.trim().length === 0) {
        emptyGroupName.push(attr);
      }
    })

    if (!notEmpty(emptyGroupName) && isAllGroupsNameValid() && groups.length < 20) {
      setIsAddGroupDisabled(false);
    } else {
      setIsAddGroupDisabled(true);
    }
    // eslint-disable-next-line
  },[groups])

  const groupInputHandler = (event, order) => {
    const updatedGroups = [...groups];
    const input = updatedGroups.find(i => i.order === order);
    input.name = event;
    input.error = errorInitialState;
    setGroups(updatedGroups);
  }

  const addGroupInputField = () => {
    if (!isAddGroupDisabled) {
      setGroups([...groups, {name: "", order: groups.length + 1, error: errorInitialState}]);
    }
  }

  const createGroup = async (group) => {
    try {
      await axios.post(
        `/api/organizations/${org_id}/groups`,
          {group: {name: group.name}},
          {
            headers: {
              "X-CSRF-TOKEN": csrfToken,
            },
          }
      );
      setIsGroupsEmpty(false);
    } catch (error) {
      Honeybadger.notify(error);
    }
  }

  const createGroupSubmissionHandler = async () => {
    if (isAllGroupsNameValid()) {
      setLoading(true);
      for (const group of groups) {
        if(notEmpty(group.name)) {
          await createGroup(group);
        }
      }
      navigate(`/${org_id}/onboarding/invitations`);
      setLoading(false);
    } else {
      navigate(`/${org_id}/onboarding/invitations`);
      setLoading(false);
    }
  }

  const isAllGroupsNameValid = () => {
    // Check if all the groups error status is set to false
    const isAllValid = Object.values(groups).every(
      value => value.error.status === false
    );

    // Check if all the groups name are empty
    const isAllEmpty = Object.values(groups).every(
      value => notEmpty(value.name) === false
    );

    return isAllValid && (!isAllEmpty || !isGroupsEmpty);
  }

  const groupNameUnique = (name, order) => {
    const updatedGroups = [...groups];

    // Check if the Group name is unique
    const result = updatedGroups.map((i) => {
      if(i.order !== order && notEmpty(name.trim())) {
        if(i.name.trim().toUpperCase() === name.trim().toUpperCase()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })

    const nameUnique = result.every(v => v === false);
    return nameUnique;
  }

  const isGroupNameValid = (name, order) => {
    if(name.match(disallowedChars)){
      const updatedGroups = [...groups];
      const input = updatedGroups.find(i => i.order === order);
      input.error = {status: true, message: t("groups.error.invalidCharacters")};
      setGroups(updatedGroups);
    } else if (!groupNameUnique(name, order)) {
      const updatedGroups = [...groups];
      const input = updatedGroups.find(i => i.order === order);
      input.error = {status: true, message: t("groups.error.uniqueness")};
      setGroups(updatedGroups);
    } else {
      return true;
    }
  }

  return(
    <Grid
      container={true}
      item={true}
      height="100%"
      wrap="wrap"
      direction="row"
      lg={12}
      md={12}
      sm={12}
    >
      <Grid
        item={true}
        xs={12}
        container={false}
        md={5}
        padding="0px !important"
      >
        <Header/>
        <Box
          display="inline-block"
          bgcolor="white"
          sx={{ padding: {xs: "40px", sm: "60px", md: "110px 80px", lg: "110px 100px"} }}
        >
          <Typography
            variant="h6"
            color="text.primary"
            fontWeight="bold"
          >
            {t("groups.title")}
          </Typography>
          <Typography
            variant="subtitle2"
            color="grey.500"
          >
            {t("groups.subtitle")}
          </Typography>
          <Box
            display="inline-block"
            paddingTop="10px"
            paddingBottom="10px"
          >
            {groups.map((field) => (
              <TextField
                variant="outlined"
                fullWidth={true}
                label={t("groups.name")}
                multiline={false}
                margin="normal"
                size="small"
                required={true}
                onChange={(e) => groupInputHandler(e.target.value, field.order)}
                key={field.order}
                onBlur={(e) => isGroupNameValid(e.target.value, field.order)}
                error={field.error && field.error.status}
                helperText={field.error.message}
              />
            ))}
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              startIcon="add"
              disabled={isAddGroupDisabled}
              sx={{ margin: '10px 0 20px 0' }}
              onClick={addGroupInputField}
            >
              {t("groups.addNew")}
            </Button>
          </Box>
          <Box
            display="inline-block"
            sx={{
              position: "relative"
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={false}
              onClick={createGroupSubmissionHandler}
            >
                {t("groups.continueBtn")}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'white',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        item={true}
        xs={12}
        container={false}
        zeroMinWidth={false}
        md={7}
        sx={{ display: {xs: "none", md: "inline-block"} }}
      >
        <Box
          id="blue-box"
          height="100%"
          display="block"
          bgcolor="primary.main"
          sx={{ position: 'relative' }}
        >
          <Box
            display="inline-block"
            height="10px"
            width="25%"
            bgcolor="secondary.main"
            sx={{ position: 'absolute', top: '0' }}
          >
          </Box>
          <Box
            display="inline-block"
            height="10px"
            width="100%"
            bgcolor="secondary.main"
            sx={{ opacity: '0.34', position: 'absolute', top: '0' }}
          />
          <Box
            display="block"
            sx={{ padding: {xs: "40px", sm: "60px", md: "190px 80px", lg: "190px 100px"} }}
          >
            <Box
              display="inline-block"
            >
              <Typography
                variant="h4"
                color="white"
                fontWeight="bold"
              >
                {t("onboardingSteps.title")}
              </Typography>
              <Typography
                variant="subtitle1"
                color="grey.300"
                paddingTop="8px"
                sx={{ maxWidth: '500px' }}
              >
                {t("onboardingSteps.subtitle")}
              </Typography>
            </Box>
            <Stack
              direction="column"
              spacing={0}
              paddingTop="12px"
              paddingBottom="28px"
              sx={{ borderBottom: '1px solid rgba(99, 217, 229, 0.25)' }}
            >
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="text.secondary"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                >
                  <Image src={lockOpen} alt="lock-open" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.createGroups")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="text.secondary"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                  borderColor="secondary.dark"
                  border="solid"
                >
                  <Image src={lockClosed} alt="lock-closed" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="secondary.dark"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.inviteMembers")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="secondary.dark"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                  borderColor="secondary.dark"
                  border="solid"
                >
                  <Image src={lockClosed} alt="lock-closed" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="secondary.dark"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.importRecords")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="secondary.dark"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                  borderColor="secondary.dark"
                  border="solid"
                >
                  <Image src={lockClosed} alt="lock-closed" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="secondary.dark"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.success")}
                </Typography>
              </Stack>
            </Stack>
            <Box
              display="inline-block"
              paddingTop="40px"
              paddingBottom="40px"
            >
              <Typography
                variant="caption"
                color="grey.300"
              >
                {t("onboardingSteps.skipCta")}
              </Typography>
              <Link
                variant="caption"
                color="secondary"
                paddingLeft="8px"
                href={'/dashboard'}
              >
                {t("onboardingSteps.skipLink")}
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default OnboardingGroups;
