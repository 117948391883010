import { Navigate } from "react-router-dom";
import useUser from "../../hooks/useUser";

function PrivateRoute({ children }) {
  const user = useUser();
  const timeNow = Date.now();

  if (!user) return <Navigate to={`/signup`} />;

  if (user) {
    if (timeNow > Date.parse(user.exp)) {
      localStorage.removeItem("session_exp");
      return <Navigate to={`/signup`} />;
    }
  }

  return children;
}

export default PrivateRoute;
