import { useState, useEffect } from 'react';
import { Typography, Grid, Box, Image, TextField, Button, Stack, Divider, FormControlLabel, Checkbox, FormControl, Link }  from '@teampassword/teampassword-design-system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import lockOpen from "../images/lock_open.svg";
import lockClosed from "../images/lock_closed.svg";
import checkIcon from "../images/check_icon.svg";
import Header from '../components/signup/Header';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Honeybadger } from '@honeybadger-io/react';
import useCsrfToken from "../hooks/useCsrfToken";
import forge from "node-forge";
import { passwordGenerator } from '../helpers';
import { useTranslation } from 'react-i18next';

function OnboardingImportRecord() {
  let navigate = useNavigate();
  let { org_id } = useParams();
  const userEmail = localStorage.getItem("tp_email");
  const csrfToken = useCsrfToken();
  const encryptedPrivateKey = localStorage.getItem("tp_pk");

  const { t } = useTranslation();

  const [org, setOrg] = useState({});
  const [user, setUser] = useState({});
  const [groups, setGroups] = useState([]);

  const initialData = {
    name: "",
    url: "",
    username: "",
    password: "",
    notes: "",
    isFavorite: false,
    shareType: "",
    shares: [],
  }

  const [data, setData] = useState(initialData);
  const [sharedWith, setSharedWith] = useState("private");
  const [isPrivate, setIsPrivate] = useState(true);
  const [sharedWithOrg, setSharedWithOrg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Handle error messages
  const errorInitialState = {status: false, message: "", isLoading: true};
  const [nameError, setNameError] = useState(errorInitialState);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const disallowedChars = /[<>]/;

  const isEmpty = (value) => {
    return !value.length > 0;
  }

  useEffect(() => {
    const getOrg = async () => {
      try {
        const response = await axios.get(`/api/organizations`);
        setOrg(response.data[0]);

        let groups = response.data[0].groups;
        groups.map((group) => (
          group.shared = false
        ))
        setGroups(groups);
      } catch (error) {
        setShowErrorMessage(true);
        Honeybadger.notify(error);
      }
    }
    getOrg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.post(`/api/user/get_user_public_key`, {email: userEmail},{
          headers: {
          "X-CSRF-TOKEN": csrfToken,
        }});

        const responseData = response.data;
        setUser(responseData);
        setData({...data, shareType: "private", shares: [{user_id: responseData.userId, organization: t("importRecord.onlyMe")}]});
      } catch (error) {
        setShowErrorMessage(true);
        Honeybadger.notify(error);
      }
    }
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const rightGridBox = document.getElementById("blue-box");
    rightGridBox.parentElement.style.height = "100%";
  },[])

  useEffect(() => {
    let selectedGroups = [];
    for (let group of groups) {
      group.shared && selectedGroups.push({group_id: group.id, organization: org.name});
    }

    if (!isEmpty(selectedGroups)) {
      setData({...data, shareType: "group", shares: selectedGroups})
    } else {
      if (!isPrivate){
        setSharedWithOrg(true);
        setData({...data, shareType: "organization", shares: [{organization_id: org.id, organization: org.name}]})
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[groups])

  useEffect(() => {
    switch (sharedWith) {
      case 'private':
        setIsPrivate(true);
        setSharedWithOrg(false);
        clearGroupsSelection();
        setData({...data, shareType: "private", shares: [{user_id: user.userId, organization: t("importRecord.onlyMe")}]})
        break;
      case 'org':
        setIsPrivate(false);
        setSharedWithOrg(true);
        setData({...data, shareType: "organization", shares: [{organization_id: org.id, organization: org.name}]})
        break;
      default:
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sharedWith])

  const isDataValid = () => {
    if(isEmpty(data.name) && isEmpty(data.url) && isEmpty(data.username) && isEmpty(data.password) && isEmpty(data.notes)) {
      return null;
    } else if(isEmpty(data.name)) {
      setNameError({status: true, message: t("importRecord.error.nameBlank"), isLoading: false});
      return false;
    } else {
      if(!nameError.status) {
        return true;
      }
    }
  }

  const isNameValid = () => {
    if(data.name.match(disallowedChars)){
      setNameError({status: true, message: t("importRecord.error.invalidCharacters"), isLoading: false});
      return false;
    } else {
      nameError.isLoading = false;
      setNameError(nameError);
      return true;
    }
  }

  const clearGroupsSelection = () => {
    const updatedGroups = [...groups];
    for (let group of updatedGroups) {
      group.shared = false;
    }
    setGroups(updatedGroups);
  }

  const nameInputHandler = (event) => {
    setData({...data, name: event.target.value})
    setNameError(errorInitialState);
  }

  const handleSharedOrg = (event) => {
    setSharedWithOrg(!sharedWithOrg);
    clearGroupsSelection();
    setData({...data, shareType: "organization", shares: [{organization_id: org_id, group_id: undefined}]})
  }

  const handleSharedGroups = (event, groupId) => {
    const updatedGroups = [...groups];
    const group =  updatedGroups.find(e => e.id === groupId);
    group.shared = !group.shared;
    setGroups(updatedGroups);
    setSharedWithOrg(false);
  }

  const encryptAES = async (data, key) => {
    const iv = forge.random.getBytes(16)
    const buffer = forge.util.createBuffer(JSON.stringify(data))
    const cipher = forge.aes.startEncrypting(key, iv, null)
    cipher.update(buffer)
    cipher.finish()
    const ciphertext = cipher.output.data
    return JSON.stringify({iv: forge.util.encode64(iv), data: forge.util.encode64(ciphertext)})
  }

  const decryptAES = async (data, key) => {
    const params = JSON.parse(data);
    const iv = forge.util.createBuffer(forge.util.decode64(params['iv']));
    const ciphertext = forge.util.decode64(params['data']);
    const buffer = forge.util.createBuffer(ciphertext);
    const cipher = forge.aes.startDecrypting(key, iv);
    cipher.update(buffer);
    cipher.finish();
    return JSON.parse(cipher.output.data);
  }

  const encrypt = async (data, pk) => {
    const publicKey = forge.pki.publicKeyFromPem(pk);
    const key = forge.random.getBytes(256 / 8);
    const encryptedKey = forge.util.encode64(publicKey.encrypt(key));
    const encryptedJson = await encryptAES(data, key);
    return {encryptedKey, encryptedJson};
  }

  const encryptData = async (dataToEncrypt) => {
    let encryptedEntries = [];

    if(isPrivate){
      const encrypted = await encrypt(dataToEncrypt, user.publicKey);
      encryptedEntries = [{encrypted_key: encrypted.encryptedKey, encrypted_json: encrypted.encryptedJson, user_id: user.userId}]
    } else {
      let options = {group_id: []};

      if (data.shares.length > 1) {
        for (let share of data.shares) {
          options.group_id.push(share.group_id);
        }
      } else {
        options = data.shares[0];
      }

      let baseUrl = "/api/public-keys?";

      if (options.organization_id) {
        baseUrl = `${baseUrl}organization_id=${options.organization_id}`
      } else if (options.group_id) {
        if (typeof options.group_id === "number") {
          baseUrl = `${baseUrl}group_id=${[options.group_id].join(',')}`
        } else {
          baseUrl = `${baseUrl}group_id=${options.group_id.join(',')}`
        }
      }

      try {
        const response = await axios.get(baseUrl);
        const sharedUsers = response.data;

        for (let sharedUser of sharedUsers) {
          const encrypted = await encrypt(dataToEncrypt, sharedUser.public_key);
          encryptedEntries.push({encrypted_key: encrypted.encryptedKey, encrypted_json: encrypted.encryptedJson, user_id: sharedUser.user_id});
        }

      } catch (error) {
        setShowErrorMessage(true);
        Honeybadger.notify(error);
      }
    }
    return encryptedEntries;
  }

  const getUserSessionToken = async () => {
    try {
      const response = await axios.post('/api/session/get_session_token',
      {
        headers: {
          "X-CSRF-TOKEN": csrfToken,
        }
      });

      return response.data.sessionToken;
    } catch (error) {
      setShowErrorMessage(true);
      Honeybadger.notify(error);
    }
  }

  const verifyEncryptedEntries = async (dataEncryptedEntries) => {
    let yourKeyedLogin = dataEncryptedEntries.filter((entry) => entry.user_id === user.userId);
    yourKeyedLogin = yourKeyedLogin[0];

    try {
      const sessionToken = await getUserSessionToken();
      const decryptedPrivateKey = forge.pki.decryptRsaPrivateKey(encryptedPrivateKey, sessionToken);
      const key = decryptedPrivateKey.decrypt(forge.util.decode64(yourKeyedLogin.encrypted_key));

      await decryptAES(yourKeyedLogin.encrypted_json, key);
      return true;
    } catch (error) {
      setShowErrorMessage(true);
      setData({...initialData, shareType: data.shareType, shares: data.shares});
      Honeybadger.notify(error);
      return false;
    }
  }

  const createRecord = async () => {
    const dataToSubmit = {
      name: data.name,
      url: data.url,
      organization_id: isPrivate ? data.shareType : org.id.toString(),
      shares: data.shares,
      share_type: data.shareType,
      organization_name: isPrivate ? t("importRecord.onlyMe") : org.name,
      is_favorite: data.isFavorite,
    }

    if (!isPrivate) {
      dataToSubmit.sharing = "on";
    }

    let dataToEncrypt = {
      username: data.username,
      password: data.password,
      notes: data.notes
    }

    dataToSubmit.encryptedEntries = await encryptData(dataToEncrypt);
    const isEncryptedEntriesVerifyed = await verifyEncryptedEntries(dataToSubmit.encryptedEntries);

    if (isEncryptedEntriesVerifyed) {
      try {
        await axios.post(`/api/accounts`, dataToSubmit,
          {
            headers: {
              "X-CSRF-TOKEN": csrfToken,
            }
          }
        );

        navigate(`/${org_id}/onboarding/success`);
      } catch(error) {
       setShowErrorMessage(true);
       setData({...initialData, shareType: data.shareType, shares: data.shares});
        Honeybadger.notify(error);
      }
    }
  }

  const importRecordSubmissionHandler = async () => {
    if(isDataValid() === null) {
      //if all fields are empty got to the next page.
      navigate(`/${org_id}/onboarding/success`);
    } else if(isDataValid() === false) {
      //if data invalid, show error.
      return;
    } else if (isDataValid() === true) {
      //if data valid, start the creation process.
      createRecord();
    }
  }

  return(
    <>
      <Grid
        container={true}
        item={true}
        height="100%"
        wrap="wrap"
        direction="row"
        lg={12}
        md={12}
        sm={12}
      >
        <Grid
          item={true}
          xs={12}
          container={false}
          md={5}
        >
          <Header/>
          <Box
            display="inline-block"
            bgcolor="white"
            sx={{ padding: {xs: "40px", sm: "60px", md: "110px 80px", lg: "110px 100px"} }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              fontWeight="bold"
            >
              {t("importRecord.title")}
            </Typography>
            <Typography
              variant="subtitle2"
              color="grey.500"
            >
              {t("importRecord.subtitle")}
            </Typography>
            <Box
              display="block"
              paddingBottom="16px"
            >
              {showErrorMessage && (
                <div>
                  <Alert severity="error">{t("importRecord.error.somethingWentWrong")}</Alert>
                </div>
              )}
              <TextField
                variant="outlined"
                fullWidth={true}
                label={t("importRecord.name")}
                multiline={false}
                margin="normal"
                size="small"
                value={data.name}
                onChange={nameInputHandler}
                required={true}
                error={nameError.status}
                helperText={nameError.message}
                onBlur={isNameValid}
              />
              <TextField
                variant="outlined"
                fullWidth={true}
                label={t("importRecord.url")}
                multiline={false}
                margin="normal"
                size="small"
                required={false}
                value={data.url}
                onChange={(event)=> {setData({...data, url: event.target.value})}}
              />
              <TextField
                variant="outlined"
                fullWidth={true}
                label={t("importRecord.username")}
                multiline={false}
                margin="normal"
                size="small"
                required={false}
                value={data.username}
                onChange={(event) => {setData({...data, username: event.target.value})}}
              />
              <Stack
                direction="row"
                spacing={0}
                hasDivider={false}
                alignItems="center"
              >
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  label={t("importRecord.password")}
                  multiline={false}
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  required={false}
                  value={data.password}
                  margin="normal"
                  onChange={(event) => {setData({...data, password: event.target.value})}}
                  sx={{ borderRadius: '0' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <Button
                        color="primary"
                        sx={{
                          borderLeft: "1px solid #c2c2c2",
                          borderRadius: "0 4px 4px 0",
                          padding: "8px",
                          marginRight: "-14px"
                        }}
                        onClick={() => {setData({...data, password: passwordGenerator(16, true, true)})}}
                      >{t("importRecord.generate")}</Button>
                    </InputAdornment>,
                  }}
                />
              </Stack>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={showPassword ? 'visibility_off' : 'visibility'}
                onClick={() => {setShowPassword(!showPassword)}}
                sx={{
                  color: "#204C6A",
                  background: "unset",
                  boxShadow: "unset",
                  padding: "4px 16px",
                  borderRadius: "24px",
                  "&:hover": {
                    backgroundColor: "#204c6a0f",
                    boxShadow: "unset",
                  }
                }}
              >
                {showPassword ? t("importRecord.hidePassword") : t("importRecord.showPassword")}
              </Button>
              {/* Commenting it out for test purpose */}
              {/* <TextField
                variant="outlined"
                fullWidth={true}
                label="Notes"
                multiline={true}
                rows={4}
                margin="normal"
                size="small"
                required={false}
                value={data.notes}
                onChange={(event) => {setData({...data, notes: event.target.value})}}
              /> */}
              {/* <Typography
                variant="caption"
                color="grey.500"
              >
                You can use the notes field for any text you want to share securely, such as business credit card information or bank details.
              </Typography> */}
              <Typography
                variant="subtitle2"
                color="primary.main"
                fontWeight="bold"
                paddingTop="20px"
                paddingBottom="10px"
              >
                {t("importRecord.shareWith")}
              </Typography>
              <Box
                display="flex"
                flexWrap="wrap"
                paddingBottom="10px"
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="private"
                    name="controlled-radio-buttons-group"
                    onChange={(event) => {setSharedWith(event.target.value)}}
                  >
                    <FormControlLabel value="private" control={<Radio />} label={t("importRecord.onlyMe")} />
                    {org &&
                      <FormControlLabel value="org" control={<Radio />} label={org.name} />
                    }
                  </RadioGroup>
                  <Stack marginLeft="19px">
                    {sharedWith === "org" &&
                      <>
                        <FormControlLabel
                          control={<Checkbox
                                    checked={sharedWithOrg}
                                    onChange={handleSharedOrg}
                                    value="organization"
                                    />}
                          label={`Everyone at ${org.name}`}
                        />
                      </>
                    }
                    {!isEmpty(groups) && sharedWith === "org" &&
                      <>
                        <Divider
                          variant="fullWidth"
                        >
                        </Divider>
                        {groups.map((group) => (
                          <FormControlLabel
                            key={group.id}
                            control={<Checkbox
                                      checked={group.shared}
                                      onChange={(event) => handleSharedGroups(event, group.id)}
                                      value="org_group"
                                      />}
                            label={group.name}
                          />
                         ))}
                      </>
                    }
                  </Stack>
                </FormControl>
              </Box>
              {/* Commenting it out for test purpose */}
              {/* <Divider
                variant="fullWidth"
              >
              </Divider>
              <FormControlLabel
                control={<Checkbox
                          checked={data.isFavorite}
                          onChange={() => {setData({...data, isFavorite: !data.isFavorite})}}
                        />}
                label="Add to favorites"
              /> */}
              <Divider
                variant="fullWidth"
              >
              </Divider>
              <Box
                display="inline-block"
                paddingTop="10px"
                paddingBottom="10px"
              >
                <Typography
                  variant="caption"
                  color="grey.500"
                  paddingTop="20px"
                  paddingBottom="26px"
                  fontSize={16}
                >
                  {t("importRecord.bulkRecords")}
                </Typography>
                <Link
                  variant="caption"
                  color="secondary"
                  paddingTop="20px"
                  paddingBottom="26px"
                  paddingLeft="8px"
                  fontSize={16}
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  href={`/dashboard#import_data_team/${org_id}`}
                >
                  {t("importRecord.clickHere")}
                </Link>
              </Box>
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={false}
              onClick={importRecordSubmissionHandler}
            >
              {t("importRecord.continue")}
            </Button>
            <Link
              display="block"
              variant="subtitle2"
              padding="20px 0"
              underline="none"
              style={{ cursor: 'pointer', color: '#9e9e9e' }}
              onClick={() => {navigate(`/${org_id}/onboarding/invitations`)}}
            >
              {t("importRecord.backLink")}
            </Link>
          </Box>
        </Grid>
        <Grid
          item={true}
          xs={12}
          container={false}
          zeroMinWidth={false}
          md={7}
          sx={{ display: {xs: "none", md: "inline-block"} }}
        >
          <Box
            id="blue-box"
            height="100%"
            display="block"
            bgcolor="primary.main"
            sx={{ position: 'relative' }}
          >
            <Box
              display="inline-block"
              height="10px"
              width="75%"
              bgcolor="secondary.main"
              sx={{ position: 'absolute', top: '0' }}
            >
            </Box>
            <Box
              display="inline-block"
              bgcolor="secondary.main"
              height="10px"
              width="100%"
              sx={{ opacity: '0.34', position: 'absolute', top: '0' }}
            >
            </Box>
            <Box
              display="block"
              sx={{ padding: {xs: "40px", sm: "60px", md: "190px 80px", lg: "190px 100px"} }}
            >
              <Box
                display="inline-block"
              >
                <Typography
                  variant="h4"
                  color="white"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.title")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="grey.300"
                  paddingTop="8px"
                  sx={{ maxWidth: '500px' }}
                >
                  {t("onboardingSteps.subtitle")}
                </Typography>
              </Box>
              <Stack
                direction="column"
                spacing={0}
                paddingTop="12px"
                paddingBottom="28px"
                sx={{ borderBottom: '1px solid rgba(99, 217, 229, 0.25)' }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  hasDivider={false}
                  alignItems="center"
                >
                  <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="grey.500"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                >
                  <Image src={checkIcon} alt="Check Icon" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="grey.500"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.createGroups")}
                </Typography>
                </Stack>
                <Box
                  display="inline-block"
                  bgcolor="grey.500"
                  width="1px"
                  height="44px"
                  marginLeft="12px"
                >
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  hasDivider={false}
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="grey.500"
                    width="24px"
                    height="24px"
                    borderRadius="20px"
                  >
                    <Image src={checkIcon} alt="Check Icon" width="14" height="14">
                    </Image>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    color="grey.500"
                    fontWeight="bold"
                  >
                    {t("onboardingSteps.inviteMembers")}
                  </Typography>
                </Stack>
                <Box
                  display="inline-block"
                  bgcolor="secondary.main"
                  width="1px"
                  height="44px"
                  marginLeft="12px"
                >
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  hasDivider={false}
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="24px"
                    height="24px"
                    bgcolor="text.secondary"
                    borderRadius="20px"
                  >
                    <Image src={lockOpen} alt="lock-open" width="14" height="14">
                    </Image>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    fontWeight="bold"
                  >
                    {t("onboardingSteps.importRecords")}
                  </Typography>
                </Stack>
                <Box
                  display="inline-block"
                  bgcolor="secondary.dark"
                  width="1px"
                  height="44px"
                  marginLeft="12px"
                >
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  hasDivider={false}
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="24px"
                    height="24px"
                    borderRadius="20px"
                    borderColor="secondary.dark"
                    border="solid"
                  >
                    <Image src={lockClosed} alt="lock-closed" width="14" height="14">
                    </Image>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    color="secondary.dark"
                    fontWeight="bold"
                  >
                    {t("onboardingSteps.success")}
                  </Typography>
                </Stack>
              </Stack>
              <Box
                display="inline-block"
                paddingTop="40px"
                paddingBottom="40px"
              >
                <Typography
                  variant="caption"
                  color="grey.300"
                >
                  {t("onboardingSteps.skipCta")}
                </Typography>
                <Link
                  variant="caption"
                  color="secondary"
                  paddingLeft="8px"
                  href={'/dashboard'}
                >
                  {t("onboardingSteps.skipLink")}
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default OnboardingImportRecord;
