import { useState, useEffect } from "react";
import useSessionValidation from "./useSessionValidation";

function useUser() {
  const [sessionValidation] = useSessionValidation();

  function getPayloadFromSessionValidation(sessionValidation) {
    return JSON.parse(sessionValidation);
  }

  const [user, setUser] = useState(() => {
    if (!sessionValidation) return null;
    return getPayloadFromSessionValidation(sessionValidation);
  });

  useEffect(() => {
    if (!sessionValidation) {
      setUser(null);
    } else {
      setUser(getPayloadFromSessionValidation(sessionValidation));
    }
  }, [sessionValidation]);

  return user;
}

export default useUser;
