import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5BKL5B9'
}

if (process.env.REACT_APP_ENV === 'production'){
  TagManager.initialize(tagManagerArgs)
}

const config = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
}

const honeybadger = Honeybadger.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <App />
    </HoneybadgerErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
