import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, Grid, Box, Image, TextField, Button, Stack, Link, FormControl, CircularProgress }  from '@teampassword/teampassword-design-system';
import lockOpen from "../images/lock_open.svg";
import lockClosed from "../images/lock_closed.svg";
import checkIcon from "../images/check_icon.svg";
import Header from '../components/signup/Header';
import { useNavigate, useParams } from "react-router-dom";
import { Select, InputLabel, Checkbox, ListItemText, Alert } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { validEmailAddress } from "../helpers";
import useCsrfToken from "../hooks/useCsrfToken";
import forge from "node-forge";
import { Honeybadger } from '@honeybadger-io/react';
import { useTranslation } from 'react-i18next';

function OnboardingInvitations() {
  let navigate = useNavigate();
  let { org_id } = useParams();
  const csrfToken = useCsrfToken();

  const { t } = useTranslation();

  const errorInitialState = {status: false, message: ""};
  const [showUpgradeErrorMessage, setShowUpgradeErrorMessage] = useState(errorInitialState);

  const [members, setMembers] = useState([{email: "", role: "", groups: [], order: 1, error: errorInitialState}]);
  const [roles] = useState(["member", "admin", "read-only"]);
  const [groups, setGroups] = useState([]);

  const [activeInvites, setActiveInvites] = useState([]);
  const [remainingInvites, setRemainingInvites] = useState(0);
  const [isAddMemberDisabled, setIsAddMemberDisabled] = useState(true);

  const [loading, setLoading] = useState(false);

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    const rightGridBox = document.getElementById("blue-box");
    rightGridBox.parentElement.style.height = "100%";
  }, [])

  useEffect(() => {
    const getGroups = async () => {
      try {
        const response = await axios.get(`/api/organizations/${org_id}`);
        const groups = response.data.groups;

        setGroups(groups);
        setActiveInvites(response.data.activeInvites);
        setRemainingInvites(response.data.remaining_invites);

        const updatedMembers = [...members];
        let member = members.find(i => i.order === 1);

        if (groups.length > 0) {
          member.groups = [groups[0]];
        }
        member.role = roles[0];
        setMembers(updatedMembers);
      } catch (error) {
        Honeybadger.notify(error);
      }
    }
    getGroups();
    // eslint-disable-next-line
  }, [])

  const memberInputHandler = (event, order) => {
    const key = Object.keys(event)[0];
    const updatedMembers = [...members];
    const member = updatedMembers.find(i => i.order === order);
    member[`${key}`] = event[`${key}`];

    if (key === 'email') {
      member.error = errorInitialState;
      if(member.email.length > 0) {
        setIsAddMemberDisabled(false);
      } else {
        setIsAddMemberDisabled(true);
      }
    }
    setMembers(updatedMembers);
  }

  const addMemberInputField = () => {
    if(isMemberInputNotEmpty() && isMemberInputValid()) {
      setMembers([...members, {email: "", role: roles[0], groups: [groups[0]], order: members.length + 1, error: errorInitialState}]);
      setRemainingInvites(remainingInvites - 1);
      setIsAddMemberDisabled(true);
    }
  }

  const isValidEmailAddress = (order) => {
    const updatedMembers = [...members];
    const member = updatedMembers.find(i => i.order === order);

    if(!validEmailAddress(member.email) && member.email.length > 0) {
      member.error = { status: true, message: t("invitations.error.invalidEmail") };
      setMembers(updatedMembers);
      setIsAddMemberDisabled(true);
    }
  }

  const isMemberInputValid = () => {
    const inputValid = members.every(v => v.error.status === false);
    return inputValid;
  }

  const isAllMemberInputEmpty = () => {
    const inputEmpty = members.every(v => v.email === '');
    return inputEmpty;
  }

  const isMemberInputNotEmpty = () => {
    const notEmpty = members.every(v => v.email.trim().length > 0);
    return notEmpty;
  }

  const showInvitationErrorMessage = (order) => {
    const updatedMembers = [...members];
    const member = updatedMembers.find(i => i.order === order);
    member.error = { status: true, message: t("invitations.error.invitationError")};
    setMembers(updatedMembers);
    setIsAddMemberDisabled(true);
  }

  const generateKeyPair = () => {
    const state = forge.pki.rsa.createKeyPairGenerationState(1024);
    let statePrivateKey = "";
    let statePublicKey = "";

    const step = () => {
      // run for 1000 ms
      if(!forge.pki.rsa.stepKeyPairGenerationState(state, 1000)) {
        setTimeout(step(), 1);
      }
      else {
        // Turn off progress indicator, use state.keys
        statePrivateKey = forge.pki.privateKeyToPem(state.keys.privateKey);
        statePublicKey = forge.pki.publicKeyToPem(state.keys.publicKey);
      }
    }

    setTimeout(step());
    return [statePrivateKey, statePublicKey];
  };

  const addMemberToGroup = async (group, invitedUserId) => {
    try {
      await axios.put(`/api/organizations/${org_id}/groups/${group.id}`,
        { added_user_id: invitedUserId },
        { headers: { "X-CSRF-TOKEN": csrfToken } }
      );
    } catch (error) {
      Honeybadger.notify(error);
    }
  }

  const saveInvitation = async (member, data) => {
    try {
      const response = await axios.post('/api/invitations',
        data,
         { headers: { "X-CSRF-TOKEN": csrfToken } }
      );

      const invitedUserId = response.data.id;

      if (member.groups.length > 0) {
        for (let group of member.groups) {
          await addMemberToGroup(group, invitedUserId);
        }
      }
    } catch (error) {
      if (error.response.status === 422) {
        setShowUpgradeErrorMessage({status: true, message: error.response.data.error});
      } else {
        showInvitationErrorMessage(member.order);
        return;
      }
    }
  }

  const findOrMakeKeys = async (member) => {
    let data = { organization_id: org_id,
      email: member.email,
      role: member.role,
    }

    try {
      await axios.get(`/api/users/?email=${member.email.trim()}`);
      return data;
    } catch (e) {
      if(e.response.status === 404) {
        const [privateKey, publicKey] = generateKeyPair();
        data.private_key = privateKey;
        data.public_key = publicKey;
        return data;
      }
    }
  }

  const createInvitation = async (member) => {
    const data = await findOrMakeKeys(member);
    const invited = activeInvites.find(v => v.email === member.email);

    if (!invited) {
      await saveInvitation(member, data);
    }
  }

  const createInvitationSubmissionHandler = async () => {
    if (isMemberInputValid() && !loading) {
      if (isAllMemberInputEmpty() || showUpgradeErrorMessage.status) {
        navigate(`/${org_id}/onboarding/import-record`);
      } else {
        setLoading(true);
        for (const member of members) {
          if(member.email.length > 0) {
            await createInvitation(member);
          }
        }

        if(isMemberInputValid()) {
          navigate(`/${org_id}/onboarding/import-record`);
          setLoading(false);
        } else {
          setLoading(false);
          return;
        }
      }
    } else {
      return;
    }
  }

  return(
    <Grid
      container={true}
      item={true}
      height="100%"
      wrap="wrap"
      direction="row"
      lg={12}
      md={12}
      sm={12}
    >
      <Grid
        item={true}
        xs={12}
        container={false}
        md={5}
      >
        <Header/>
        <Box
          display="block"
          bgcolor="white"
          sx={{ padding: {xs: "40px", sm: "60px", md: "110px 80px", lg: "110px 100px"} }}
        >
          <Typography
            variant="h6"
            color="text.primary"
            fontWeight="bold"
          >
            {t("invitations.title")}
          </Typography>
          <Typography
            variant="subtitle2"
            color="grey.500"
          >
              {t("invitations.subtitle")}
          </Typography>
          <Box
            display="block"
          >
            {showUpgradeErrorMessage.status && (
              <div>
                <Alert severity="error">{showUpgradeErrorMessage.message}</Alert>
              </div>
            )}
            <Stack
              direction="column"
              spacing={2}
            >
            {members.map((member) => (
              <Box key={member.order}>
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  label={t("invitations.email")}
                  multiline={false}
                  margin="normal"
                  size="small"
                  value={member.email}
                  onBlur={() => isValidEmailAddress(member.order)}
                  onChange={(e) => memberInputHandler({email: e.target.value}, member.order)}
                  error={member.error && member.error.status}
                  helperText={member.error.message}
                  sx={{ margin: "16px 0"}}
                  />
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="role">Role</InputLabel>
                    <Select
                      labelId="role"
                      id="role-select"
                      label={t("invitations.role")}
                      required={true}
                      value={member.role}
                      onChange={(e) => memberInputHandler({role: e.target.value}, member.order)}
                    >
                      {roles.map((role) => (
                        <MenuItem
                          value={role}
                          key={role}
                        >
                          {capitalize(role)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {groups.length > 0 &&
                    <FormControl fullWidth size="small">
                      <InputLabel id="group">Group</InputLabel>
                      <Select
                        labelId="group"
                        id="group-select"
                        label={t("invitations.group")}
                        required={true}
                        multiple
                        value={member.groups}
                        renderValue={(selected) => selected.map(g => g.name).join(', ')}
                        onChange={(e) => memberInputHandler({groups: e.target.value}, member.order)}
                      >
                        {groups.map((group) => (
                          <MenuItem
                            value={group}
                            key={group.id}
                          >
                            <Checkbox checked={member.groups.indexOf(group) > -1} />
                            <ListItemText primary={group.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Stack>
              </Box>
            ))}
            </Stack>
            {remainingInvites > 1 &&
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                startIcon="add"
                disabled={isAddMemberDisabled}
                sx={{ marginTop: '30px' }}
                onClick={addMemberInputField}
              >
                {t("invitations.addPeople")}
              </Button>
            }
          </Box>
          <Box
            display="inline-block"
            sx={{
              position: "relative",
              marginTop: '30px'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
              onClick={createInvitationSubmissionHandler}
            >
              {t("invitations.continueBtn")}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'white',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
          <Link
            display="block"
            variant="subtitle2"
            padding="20px 0"
            underline="none"
            style={{ cursor: 'pointer', color: '#9e9e9e' }}
            onClick={() => {navigate(`/${org_id}/onboarding/groups`)}}
          >
            {t("invitations.backLink")}
          </Link>
        </Box>
      </Grid>
      <Grid
        item={true}
        xs={12}
        container={false}
        zeroMinWidth={false}
        md={7}
        sx={{ display: {xs: "none", md: "inline-block"} }}
      >
        <Box
          id="blue-box"
          height="100%"
          display="block"
          bgcolor="primary.main"
          sx={{ position: 'relative' }}
        >
          <Box
            display="inline-block"
            bgcolor="secondary.main"
            width="50%"
            height="10px"
            sx={{ position: 'absolute', top: '0' }}
          >
          </Box>
          <Box
            display="inline-block"
            bgcolor="secondary.main"
            width="100%"
            height="10px"
            sx={{ opacity: '0.34', position: 'absolute', top: '0' }}
          >
          </Box>
          <Box
            display="inline-block"
            paddingRight="100px"
            paddingLeft="100px"
          >
            <Box
              display="inline-block"
              marginTop="168px"
            >
              <Typography
                variant="h4"
                color="white"
                fontWeight="bold"
              >
                {t("onboardingSteps.title")}
              </Typography>
              <Typography
                variant="subtitle1"
                color="grey.300"
                paddingTop="8px"
                sx={{ maxWidth: '500px' }}
              >
                {t("onboardingSteps.subtitle")}
              </Typography>
            </Box>
            <Stack
              direction="column"
              spacing={0}
              paddingTop="12px"
              paddingBottom="28px"
              sx={{ borderBottom: '1px solid rgba(99, 217, 229, 0.25)' }}
            >
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="grey.500"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                >
                  <Image src={checkIcon} alt="Check Icon" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="grey.500"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.createGroups")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="grey.500"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                  bgcolor="text.secondary"
                  borderRadius="20px"
                >
                  <Image src={lockOpen} alt="lock-open" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.inviteMembers")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="text.secondary"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                  borderColor="secondary.dark"
                  border="solid"
                >
                  <Image src={lockClosed} alt="lock-closed" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="secondary.dark"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.importRecords")}
                </Typography>
              </Stack>
              <Box
                display="inline-block"
                bgcolor="secondary.dark"
                width="1px"
                height="44px"
                marginLeft="12px"
              >
              </Box>
              <Stack
                direction="row"
                spacing={2}
                hasDivider={false}
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                  borderRadius="20px"
                  borderColor="secondary.dark"
                  border="solid"
                >
                  <Image src={lockClosed} alt="lock-closed" width="14" height="14">
                  </Image>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="secondary.dark"
                  fontWeight="bold"
                >
                  {t("onboardingSteps.success")}
                </Typography>
              </Stack>
            </Stack>
            <Box
              display="inline-block"
              paddingTop="40px"
              paddingBottom="40px"
            >
              <Typography
                variant="caption"
                color="grey.300"
              >
                {t("onboardingSteps.skipCta")}
              </Typography>
              <Link
                variant="caption"
                color="secondary"
                paddingLeft="8px"
                href={'/dashboard'}
              >
                {t("onboardingSteps.skipLink")}
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default OnboardingInvitations;
