import { Typography, Box, TextField, IconButton, CircularProgress, Button }  from '@teampassword/teampassword-design-system';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation, Trans } from 'react-i18next';

const PasswordStep = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="bold"
      >
        {t('signup.passwordStep.title')}
      </Typography>
      <Typography
        variant="subtitle2"
        color="grey.500"
      >
        <Trans i18nKey="signup.passwordStep.subtitle" components={{typography: <Typography variant="subtitle2" fontWeight="bold" display="inline-block"></Typography>}}/>
      </Typography>
      {props.couponCode &&
        <Alert severity="success" sx={{marginTop: "10px"}}>{t('signup.passwordStep.couponAlert')} "{props.couponCode}".</Alert>
      }
      <Box
        display="inline-block"
        paddingTop="10px"
        paddingBottom="10px"
      >
        {props.showErrorMessage.status && (
          <div>
            <Alert severity="error">{props.showErrorMessage.message}</Alert>
          </div>
        )}
        <TextField
          variant="outlined"
          fullWidth={true}
          label={t('signup.passwordStep.masterPassword')}
          multiline={false}
          margin="normal"
          size="small"
          required={true}
          type={props.showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={props.handleClickShowPassword}
                  size="small"
                  edge="end"
                  tabIndex={-1}
                >
                  {props.showPassword ? 'visibility_off' : 'visibility'}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={props.password}
          onChange={props.passwordInputHandler}
          onBlur={props.isPasswordValid}
          error={props.passwordError.status}
          helperText={props.passwordError.message}
        />
        <TextField
          variant="outlined"
          fullWidth={true}
          label={t('signup.passwordStep.confirmPassword')}
          margin="normal"
          size="small"
          required={true}
          type={props.showPassword ? 'text' : 'password'}
          value={props.confirmPassword}
          onChange={props.confirmPasswordInputHandler}
          onBlur={props.isPasswordConfirmationValid}
          error={props.confirmPasswordError.status}
          helperText={props.confirmPasswordError.message}
        />
      </Box>
      <Box
        marginBottom="16px"
      >
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={(e) => {props.setReCaptchaToken(e)}}/>
      </Box>
      <Box
        display="inline-block"
        sx={{
          position: "relative"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={props.disableButton()}
          onClick={props.signUpSubmissionHandler}
        >
            {t('signup.passwordStep.btn')}
        </Button>
        {props.loading && (
          <CircularProgress
            size={24}
            sx={{
              color: 'white',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </>
  )
}

export default PasswordStep;
