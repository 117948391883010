import React, { useEffect } from "react";
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { UXPinWrapper } from "@teampassword/teampassword-design-system";
import SignUp from './pages/SignUp';
import OnboardingGroups from './pages/OnboardingGroups';
import OnboardingInvitations from './pages/OnboardingInvitations';
import OnboardingImportRecord from './pages/OnboardingImportRecord';
import OnboardingSuccess from './pages/OnboardingSuccess';
import PrivateRoute from "./components/auth/PrivateRoute";
import './i18n';

const Redirect = (props) => {
  useEffect(() => {
     window.location.href = props.to;
     // eslint-disable-next-line
  }, []);
  return;
};

function App() {
  return (
    <>
      <UXPinWrapper>
        <Router>
          <Routes>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path='/:org_id/onboarding/groups' element={<PrivateRoute><OnboardingGroups /></PrivateRoute>}></Route>
            <Route path='/:org_id/onboarding/invitations' element={<PrivateRoute><OnboardingInvitations /></PrivateRoute>}></Route>
            <Route path='/:org_id/onboarding/import-record' element={<PrivateRoute><OnboardingImportRecord /></PrivateRoute>}></Route>
            <Route path='/:org_id/onboarding/success' element={<PrivateRoute><OnboardingSuccess /></PrivateRoute>}></Route>
            <Route path="/dashboard*" element={<Redirect to={"/dashboard"}/>} />
            <Route path="*" element={<Redirect to={"/404"}/>} />
          </Routes>
        </Router>
      </UXPinWrapper>
    </>
  );
}

export default App;
