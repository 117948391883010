import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json'
import ja from './locales/ja.json'
import ko from './locales/ko.json'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: { translation: en },
      ja: { translation: ja },
      ko: { translation: ko },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
