import { useState } from "react";

function useSessionValidation() {
  const [sessionValidation, setSessionValidationInternal] = useState(() => {
    return localStorage.getItem("session_exp");
  });

  const setSessionValidation = (newSessionValidation) => {
    localStorage.setItem("session_exp", newSessionValidation);
    setSessionValidationInternal(newSessionValidation);
  };

  return [sessionValidation, setSessionValidation];
}

export default useSessionValidation;
