import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, Grid, Box, Image, Button, Stack, Link }  from '@teampassword/teampassword-design-system';
import { getCookie } from "../helpers";
import { setCookie } from "../helpers";
import Header from '../components/signup/Header';
import AccountStep from "../components/signup/AccountStep";
import PasswordStep from "../components/signup/PasswordStep";
import useCsrfToken from "../hooks/useCsrfToken";
import { Honeybadger } from '@honeybadger-io/react';
import g2Badges from "../images/g2badges.png";
import quotes from "../images/quotes-icon.png";
import forge from "node-forge";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function SignUp() {
  const [searchParams] = useSearchParams();
  let couponCode = searchParams.get("coupon_code");
  let planType = searchParams.get("plan_type");
  let cohort = searchParams.get("cohort");
  let planTier = searchParams.get("plan_tier");
  let locale = searchParams.get("locale");
  let currency = "usd";

  if (locale === "ja") {
    currency = "jpy";
  }
  else if (locale === "ko") {
    currency = "krw";
  }

  const { t } = useTranslation();

  useEffect(() => {
    if (locale === "ja") {
      i18n.changeLanguage(locale);
      document.cookie = `locale=${locale}`;
    }
    else if (locale === "ko") {
      i18n.changeLanguage(locale);
      document.cookie = `locale=${locale}`;
    }
    else {
      i18n.changeLanguage("en");
      document.cookie = "locale=en"
    }
  }, [locale]);

  const csrfToken = useCsrfToken();
  let navigate = useNavigate();

  useEffect(() => {
    const getCSRFToken = async () => {
      try {
        await axios.post('/api/session/get_csrf_token');
      } catch(error) {
        Honeybadger.notify(error);
      }
    }
    getCSRFToken();
  },[])

  const errorInitialState = {status: false, message: "", isLoading: true};

  const [adminName, setAdminName] = useState("");
  const [adminNameError, setAdminNameError] = useState(errorInitialState);

  const [teamName, setTeamName] = useState("");
  const [teamNameError, setTeamNameError] = useState(errorInitialState);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(errorInitialState);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(errorInitialState);

  const [showPassword, setShowPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(errorInitialState);

  const [reCaptchaToken, setReCaptchaToken] = useState("invalid_token");

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submited, setSubmited] = useState(false);

  // Handle error messages
  const [showErrorMessage, setShowErrorMessage] = useState({status: false, message: t("signup.error.somethingWentWrong")});

  useEffect(() => {
    const rightGridBox = document.getElementById("blue-box");
    rightGridBox.parentElement.style.height = "100%";
  }, [])

  useEffect(() => {
    const errors = [adminNameError.status, teamNameError.status, emailError.status];
    const inputValid = errors.every(v => v === false);

    const isLoading = [adminNameError.isLoading, teamNameError.isLoading, emailError.isLoading];
    const loaded = isLoading.every(v => v === false);

    if(inputValid && loaded){
      nextStep();
    } else {
      return;
    }
    // eslint-disable-next-line
  },[submited])

  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  const adminNameInputHandler = (event) => {
    setAdminName(event.target.value);
    setAdminNameError(errorInitialState);
    setSubmited(false);
  }

  const teamNameInputHandler = (event) => {
    setTeamName(event.target.value);
    setTeamNameError(errorInitialState);
    setSubmited(false);
  }

  const emailInputHandler = (event) => {
    setEmail(event.target.value);
    setEmailError(errorInitialState);
    setSubmited(false);
  }

  const passwordInputHandler = (event) => {
    setPassword(event.target.value);
    setPasswordError(errorInitialState);
    setConfirmPasswordError(errorInitialState);
  };

  const confirmPasswordInputHandler = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError(errorInitialState);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const disallowedChars = /[<>'`/.:]/;

  const isAdminNameValid = () => {
    if(adminName.match(disallowedChars)){
      setAdminNameError({status: true, message: t("signup.error.invalidCharacters"), isLoading: false});
    } else {
      adminNameError.isLoading = false;
      setAdminNameError(adminNameError);
    }
  }

  const isTeamNameValid = async () => {
    if(teamName.match(disallowedChars)){
      setTeamNameError({status: true, message: t("signup.error.invalidCharacters"), isLoading: false});
      return;
    }

    const fetchCheckTeam = async () => {
      try {
        const response = await axios.post(
          '/api/organization_signup/check_team_name',
          { organization_name: teamName },
          {
            headers: {
              "X-CSRF-TOKEN": csrfToken,
            }
          }
        );
        const responseStatus = response.status;
        if(responseStatus === 200) {
          teamNameError.isLoading = false;
          setTeamNameError(teamNameError);
        }
      } catch (error) {
        if(error.response.status === 400) {
          setTeamNameError({ status: true, message: error.response.data.error, isLoading: false });
        } else if (error.response.status === 409) {
          setTeamNameError({ status: true, message: error.response.data.message, isLoading: false});
        } else {
          setShowErrorMessage(prevState => ({...prevState, status: true}));
          reloadPage();
        }
      }
    };
    await fetchCheckTeam();
  }

  const isEmailUnique = async () => {
    if ((email.includes('support') && email.includes('@teampassword.com')) ||
         email.includes('qq.com') ||
         email.includes('sina.com') ||
         !isValidEmailAddress(email)) {
      setEmailError({ status: true, message: t("signup.error.invalidEmail") });
      return;
    }

    const fetchCheckEmail = async () => {
      try {
        const response = await axios.post(
          `/api/organization_signup/check_email`,
          { email: email },
          {
            headers: {
              "X-CSRF-TOKEN": csrfToken,
            }
          }
        );
        const responseStatus = response.status;
        if (responseStatus === 200) {
          emailError.isLoading = false;
          setEmailError(emailError);
        }
      } catch (error) {
        if(error.response.status === 400) {
          setEmailError({ status: true, message: error.response.data.error, isLoading: false });
        } else if (error.response.status === 409) {
          setEmailError({ status: true, message: error.response.data.message, isLoading: false });
        } else {
          setShowErrorMessage(prevState => ({...prevState, status: true}));
          reloadPage();
        }
      }
    };
    await fetchCheckEmail();
  }

  const isValidEmailAddress = (emailAddress) => {
    // eslint-disable-next-line
    const emailRegex = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    return emailRegex.test(emailAddress);
  }

  const isPasswordValid = () => {
    if(password.trim().length > 0 && password.trim().length < 12){
      setPasswordError({ status: true, message: t("signup.error.passwordLenth")})
    }
  }

  const isPasswordConfirmationValid = () => {
    if(confirmPassword.trim().length > 0 && password !== confirmPassword) {
      setConfirmPasswordError({ status: true, message: t("signup.error.passwordConfirmation")})
    }
  }

  const generateKeyPair = function() {
    const state = forge.pki.rsa.createKeyPairGenerationState(1024);
    let encryptedPrivateKey = "";
    let statePublicKey = "";

    const step = () => {
      // run for 1000 ms
      if(!forge.pki.rsa.stepKeyPairGenerationState(state, 1000)) {
        setTimeout(step(), 1);
      }
      else {
        // Turn off progress indicator, use state.keys
        encryptedPrivateKey = forge.pki.encryptRsaPrivateKey(state.keys.privateKey, password);
        statePublicKey = forge.pki.publicKeyToPem(state.keys.publicKey);
      }
    }

    setTimeout(step());
    return [encryptedPrivateKey, statePublicKey];
  };

  const nextStep = () => {
    setStep(1);
  }

  const verifyAccountInputs = async () => {
    isAdminNameValid();
    await isTeamNameValid();
    await isEmailUnique();

    setSubmited(true);
  }

  const getUserSessionToken = async () => {
    try {
      const response = await axios.post('/api/session/get_session_token',
      {
        headers: {
          "X-CSRF-TOKEN": csrfToken,
        }
      });

      return response.data.sessionToken;
    } catch (error) {
      if (error.response.data.error) {
        setShowErrorMessage({status: true, message: error.response.data.error});
      } else {
        setShowErrorMessage(prevState => ({...prevState, status: true}));
      }
      Honeybadger.notify(error);
      setLoading(false);
    }
  }

  async function signUpSubmissionHandler() {
    const data = {
      name: adminName,
      organization_name: teamName,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      utm_source: getCookie("_uc_utm_source"),
      utm_medium: getCookie("_uc_utm_medium"),
      utm_campaign: getCookie("_uc_utm_campaign"),
      utm_content: getCookie("_uc_utm_content"),
      utm_term: getCookie("_uc_utm_term"),
      initial_referrer: getCookie("_uc_referrer"),
      last_referrer: getCookie("_uc_last_referrer"),
      initial_landing_page: getCookie("_uc_inital_landing_page"),
      sign_up_visit_count: getCookie("_uc_visits"),
      teamsid_session: "",
      token: reCaptchaToken,
      coupon: couponCode ? couponCode : null,
      plan_type: planType ? planType : null,
      cohort: cohort ? cohort : null,
      plan_tier: planTier ? planTier: null,
      locale: locale ? locale : "en",
      currency: currency ? currency : "usd"
    };

    isPasswordConfirmationValid();

    if (password === confirmPassword) {
      setLoading(true);
      const [encryptedPrivateKey, publicKey] = generateKeyPair();
      data.encrypted_private_key = encryptedPrivateKey;
      data.public_key = publicKey;

      try {
        const response = await axios.post(
          '/api/organization_signup',
          data,
          {
            headers: {
              "X-CSRF-TOKEN": csrfToken,
            }
          }
        );

        const createdOrg = await response.data;
        const organizationId = createdOrg.id;
        data.organization_id = organizationId;

        try {
          await axios.post(
            '/api/new_organization_notification',
            data,
            {
              headers: {
                "X-CSRF-TOKEN": csrfToken,
              }
            }
          );

          try {
            await axios.post(
              '/api/session',
              { email: email, password: password },
              {
                headers: {
                  "X-CSRF-TOKEN": csrfToken,
                }
              }
            );

            const privateKey = forge.pki.decryptRsaPrivateKey(encryptedPrivateKey, password);
            const userSessionToken = await getUserSessionToken();

            //Store encrypted private key with session token
            const encryptedPrivateKeyWithSessionToken = forge.pki.encryptRsaPrivateKey(privateKey, userSessionToken);

            localStorage.setItem("tp_email", email);
            localStorage.setItem("tp_pk", encryptedPrivateKeyWithSessionToken);
            setCookie("tp_pk", encryptedPrivateKeyWithSessionToken);

            // Set session expiration time
            const exp_time = new Date();
            exp_time.setHours(exp_time.getHours() + 4);
            localStorage.setItem("session_exp", JSON.stringify({'email': email, 'exp': exp_time}));

            navigate(`/${organizationId}/onboarding/groups`);
            setLoading(false);
          } catch (error) {
            if (error.response.data.error) {
              setShowErrorMessage({status: true, message: error.response.data.error});
            } else {
              setShowErrorMessage(prevState => ({...prevState, status: true}));
            }
            Honeybadger.notify(error);
            setLoading(false);
            reloadPage();
          }

        } catch (error) {
          if (error.response.data.error) {
            setShowErrorMessage({status: true, message: error.response.data.error});
          } else {
            setShowErrorMessage(prevState => ({...prevState, status: true}));
          }
          Honeybadger.notify(error);
          setLoading(false);
          reloadPage();
        }

      } catch (error) {
        if (error.response.data.error) {
          setShowErrorMessage({status: true, message: error.response.data.error});
        } else {
          setShowErrorMessage(prevState => ({...prevState, status: true}));
        }
        Honeybadger.notify(error);
        setLoading(false);
      }
    }
  }

  const disableButton = () => {
    if(!password || !confirmPassword || passwordError.status || confirmPasswordError.status){
      return true;
    }
    return false;
  }

  const accountStep = () => {
    return (
      <AccountStep
      adminName={adminName}
      adminNameInputHandler={adminNameInputHandler}
      isAdminNameValid={isAdminNameValid}
      adminNameError={adminNameError}
      teamName={teamName}
      teamNameInputHandler={teamNameInputHandler}
      isTeamNameValid={isTeamNameValid}
      teamNameError={teamNameError}
      email={email}
      emailInputHandler={emailInputHandler}
      isEmailUnique={isEmailUnique}
      emailError={emailError}
      verifyAccountInputs={verifyAccountInputs}
      showErrorMessage={showErrorMessage}
      couponCode={couponCode}
      />
    )
  }

  const passwordStep = () => {
    return (
      <PasswordStep
      showPassword={showPassword}
      handleClickShowPassword={handleClickShowPassword}
      password={password}
      passwordInputHandler={passwordInputHandler}
      isPasswordValid={isPasswordValid}
      passwordError={passwordError}
      confirmPassword={confirmPassword}
      confirmPasswordInputHandler={confirmPasswordInputHandler}
      isPasswordConfirmationValid={isPasswordConfirmationValid}
      confirmPasswordError={confirmPasswordError}
      setReCaptchaToken={setReCaptchaToken}
      disableButton={disableButton}
      signUpSubmissionHandler={signUpSubmissionHandler}
      loading={loading}
      showErrorMessage={showErrorMessage}
      couponCode={couponCode}
      />
    )
  }

  const stepPages = [accountStep(), passwordStep()];

  return(
    <Grid
      container={true}
      item={true}
      height="100%"
      wrap="wrap"
      direction="row"
      lg={12}
      md={12}
      sm={12}
    >
      <Grid
        item={true}
        xs={12}
        container={false}
        md={5}
      >
        <Header showSignIn={true}/>
        <Box
          display="inline-block"
          bgcolor="white"
          sx={{
            padding: {xs: "0 60px", sm: "0 100px", md: "0 100px"},
            marginTop: {xs: "56px", md: "110px"}
          }}
        >
          {stepPages[step]}
          <Box
            display="inline-block"
            paddingTop="20px"
            paddingBottom="20px"
            style={{ fontStyle: "italic" }}
          >
            <Typography
              variant="caption"
              color="grey.500"
            >
              {t("signup.terms")}
            </Typography>
            <Link
              underline="none"
              color="secondary"
              variant="caption"
              href="https://teampassword.com/terms"
              sx= {{
                marginLeft: "4px"
              }}
            >
              {t("signup.terms-link")}
            </Link>
            <Typography
              variant="caption"
              color="grey.500"
            >
              {t("signup.privacy-policy")}
            </Typography>
            <Link
              underline="none"
              color="secondary"
              variant="caption"
              href="https://teampassword.com/privacy"
              sx= {{
                marginLeft: "4px"
              }}
            >
              {t("signup.privacy-policy-link")}
            </Link>
            <Typography
                variant="caption"
                color="grey.500"
            >
              {t("signup.accept-tos-and-pp")}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item={true}
        xs={12}
        container={false}
        zeroMinWidth={false}
        md={7}
        sx={{ display: {xs: "none", md: "inline-block"} }}
      >
        <Box
          id="blue-box"
          display="block"
          bgcolor="primary.main"
          height="100%"
          paddingLeft="100px"
          paddingRight="100px"
        >
          <Stack
            direction="row"
            spacing={0}
            alignContent="flex-end"
            justifyContent="flex-end"
            alignItems="center"
            paddingTop="20px"
            paddingBottom="20px"
          >
            <Typography
              variant="inherit"
              fontWeight="normal"
              color="white"
              paddingRight="22px"
            >
              {t("signup.signinCta")}
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              href="/dashboard#login"
            >
              {t("signup.signinBtn")}
            </Button>
          </Stack>
          <Box
            display="inline-block"
            marginTop="100px"
          >
            <Typography
              variant="h4"
              color="white"
              fontWeight="bold"
            >
              {t("signup.reviewTitle")}
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            paddingTop="48px"
            paddingBottom="35px"
            sx={{ borderBottom: '1px solid rgba(99, 217, 229, 0.25)' }}
          >
            <Box
              display="inline-block"
              paddingRight="32px"
            >
              <Image
                alt="Quotes Icon"
                width="57px"
                objectFit="contain"
                src={quotes}
                height="46px"
                style={{ filter: 'saturate(40)' }}
              />
            </Box>
            <Box
              display="inline-block"
            >
              <Typography
                variant="h3"
                fontSize={20}
                sx={{ lineHeight: '30px' }}
                color="white"
              >
                {t("signup.review")}
              </Typography>
              <Typography
                variant="inherit"
                fontSize={14}
                paddingTop="48px"
                sx={{ lineHeight: '30px' }}
                color="white"
                fontWeight="light"
              >
                – Tyler, The Infinite Agency
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={0}
            paddingTop="40px"
            paddingBottom="36px"
            alignContent="flex-start"
            alignItems="flex-start"
            hasDivider={false}
          >
            <Box
              display="inline-block"
            >
              <Image
                alt="G2 Badges"
                objectFit="contain"
                src={g2Badges}
                style={{ maxWidth: '294px' }}
              />
            </Box>
            <Box
              display="inline-block"
              marginLeft="60px"
            >
              <Typography
                variant="h6"
                fontSize={20}
                color="white"
                fontWeight="bold"
                lineHeight={-5}
              >
                {t("signup.g2Reviews")}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SignUp;
